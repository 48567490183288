import React from 'react';

const Help= () => {
    return (
      <main className="BodyContent">
        <h1>Preguntas más frecuentes</h1>
      </main>
    );
  }
  
  export default Help;