import React from 'react';
import ImageCard from '../Solutions/solutions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Working1 from "./../../img/Working1.png";
import Working2 from "./../../img/Working2.png";
import Working3 from "./../../img/Working3.png";
import "./workingCards.css";

const WorkCards = () => {
    return (
        <Row>
            <Col xs={12} lg={4}>
                <ImageCard image={Working1} phrase="Identifica tus procesos regulatorios<br />Selecciona sus características" />
            </Col>
            <Col xs={12} lg={4}>
                <ImageCard image={Working2} phrase="Digitaliza tu proceso" />
            </Col>
            <Col xs={12} lg={4}>
                <ImageCard image={Working3} phrase="Documenta y organiza tu información digital<br />Comparte tus registros a tu comunidad" />
            </Col>
        </Row>
    );
}

export default WorkCards;