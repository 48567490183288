import React from 'react';
import ImageCard from '../Componentes/Solutions/solutions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WorkCards from '../Componentes/WorkingCards/workingCards';
import { Container } from 'react-bootstrap';
import WorkingInitial from '../img/WorkingInitial.png';

const Solution = () => {
    return (
        <div className="contSolutions BodyContent">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 className="SolTitle">Trabajar con un software debe ser fácil</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p className="SolPhrase">Tu proceso, es parte de una cadena de suministro, suma esfuerzos y registra
                            los procesos manuales, remotos y aislados,<br /> y complejos, transfórmalos en un procedimiento simple,
                            eficiente, autoguiado, intuitivo para tus colaboradores<br /> y confiables para tus clientes y proveedores.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} className='initImgPhrase'>
                                <img className="workInitial" src={WorkingInitial} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='initImgPhrase'>
                                <p className="SolBottomPhrase">Crumbs es cloud, Crumbs es móvil, Crumbs todo SUMA.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <WorkCards />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Solution;