import React from "react";
import './phrase.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Phrase = () => {
    return (
        <div className="secFrase">
            <Container>
                <Row className="organize3">
                    <Col>
                        <h1 className="headerExp3">Redefine tu empresa</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} md={1} lg={3}>
                    </Col>
                    <Col xs={12} md={10} lg={6}>
                        <p className="textExp33">Crumbs no es un sistema, es la información flexible de tu empresa,
                            revisa el flujo de efectivo que necesitas, las piezas que salen y entran
                            a tus almacenes, camiones, oficina, documenta todo lo necesario
                            para centralizar la información, Crumbs es la herramienta de monitoreo
                            más práctica del mercado.
                        </p>
                    </Col>
                    <Col xs={0} md={1} lg={3}>
                    </Col>
                </Row>
                <Row className="contentExp3">
                    <Col>
                        <Row>
                            <Col>
                                <p className="textExp13">Ordena, visualiza y contabiliza en tiempo real tus operaciones</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="textExp23">Entrena a tu equipo de trabajo para que genere los datos que necesitas
                                para maximizar tus procesos, para identificar los errores.<br />
                                Para converger entre lo administrativo, lo operativo, lo regulatorio, y dar el siguiente
                                paso en la evolución de tu negocio.</p>
                            </Col>
                        </Row>
                        <Row className="colImg3">
                            <Col xs={12} className="colImg3">
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Phrase;