import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import ProductExplanation from '../Componentes/ProductExplanation/productExp';

const AboutUs= () => {
    return (
      <div className="contProduct BodyContent">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 className="ProductTitle">Crumbs, digitaliza<br/>
                        simplifica y estructura</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ProductExplanation />
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
  
  export default AboutUs;