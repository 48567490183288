import React from "react";
import logo from './../../img/Logo.png';
import './header.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Header = () => {
    return (
        <div className="App-header">
            <Navbar collapseOnSelect expand="lg">
                <Container fluid>
                    <Navbar.Brand href='/'><img src={logo} className="App-logo" alt="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto resNavBar">
                            <Nav.Link href="/product" className="navigWords underline-link">Producto</Nav.Link >
                            <Nav.Link href='/solutions' className="navigWords underline-link">Soluciones</Nav.Link >
                            <Nav.Link href='/plans' className="navigWords underline-link">Precios</Nav.Link >
                        </Nav>
                        <Nav>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col>
                                            <Nav.Link className="bot" href="https://app.crumbs.software/login" target="_blank"><button className="boton">Iniciar Sesion</button></Nav.Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Nav.Link className="bot" href="https://app.crumbs.software/login" target="_blank"><button className="boton2">DEMO Gratis</button></Nav.Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;