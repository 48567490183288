import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "./productExp.css";
import Bulb from "./../../img/Bulb.png";

const ProductExp = () => {
    return (
        <Container className="ProductExpContainer">
            <Row className="ProdCardRow">
                <Col xs={12} md={6} lg={4} className="ProdCardColumn">
                    <Card className="ProductCard">
                        <Card.Body>
                            <Card.Title className="ProductCardTitle">¿Qué es Crumbs?</Card.Title>
                            <Card.Text className="ProductCardText">
                                Crumbs digitaliza procesos manuales.<br />
                                Crumbs digitaliza procesos en situaciones remotas.<br />
                                Crumbs organiza y documenta tus operaciones online y offline.
                            </Card.Text>
                            <Card className="ProductCardInside">
                                <Card.Body>
                                    <Row className="ProductCardTextInside">
                                        <ul className="custom-bullets switch">
                                            <li>Simplifica tu procedimiento</li>
                                            <li>Selecciona sus características</li>
                                            <li>Digitaliza tu proceso</li>
                                        </ul>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={4} className="ProdCardColumn">
                    <Card className="ProductCard">
                        <Card.Body>
                            <Card.Title className="ProductCardTitle">¿Qué ofrece Crumbs?</Card.Title>
                            <Card.Text className="ProductCardText">
                                Materialidad de Operaciones.<br />
                                Comunidad y grupos de usuarios informados en la nube.<br />
                                Fortalece la comuniación de la cadena de suministro de tu empresa.
                            </Card.Text>
                            <Card className="ProductCardInside">
                                <Card.Body>
                                    <Row className="ProductCardTextInside">
                                        <ul className="custom-bullets star">
                                            <li>Identifica tus procesos regulatorios</li>
                                            <li>Documenta y organiza tu información digital</li>
                                            <li>Comparte tus registros a tu comunidad</li>
                                        </ul>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={4} className="ProdCardColumn">
                    <Card className="ProductCardImg">
                        <Card.Body>
                            <Card.Title className="ProductCardTitle">Propuesta de Crumbs</Card.Title>
                            <Card.Text className="ProdCardImg">
                                <img src={Bulb} alt="Bulb" className="BulbImg" />
                            </Card.Text>
                            <Card className="ProductCardInsideCheck">
                                <Card.Body>
                                    <Row className="ProductCardTextInside">
                                        <ul className="custom-bullets check">
                                            <li>Gestión, Control y Comunicación en tu empresa.</li>
                                            <li>Simplificación de procesos</li>
                                            <li>Ahorro de tiempo</li>
                                        </ul>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductExp;