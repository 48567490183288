import React from "react";
import MovsTerm from "./../../img/MovementsTerm.png";
import './explanation2.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Explicacion2 = () => {
    return (
        <Container className="exp2">
            <Row className="organize2">
                <Col>
                    <h1 className="headerExp2">Potencializa tu información diaria</h1>
                </Col>
            </Row>
            <Row className="contentExp2">
                <Col>
                    <Row>
                        <Col>
                            <p className="textExp12">Ordena, visualiza y contabiliza en tiempo real tus operaciones</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="textExp22">Captura toda la información simple de tu empresa, en tiempos optimizados <br/>
                            con recursos existentes, simplifica la información que tu empresa realiza diariamente</p>
                        </Col>
                    </Row>
                    <Row className="colImg2">
                        <Col xs={12} className="colImg2">
                            <img className="termView2 d-none d-lg-block" src={MovsTerm} alt="App" />
                            <img className="termViewmd2 d-none d-md-block d-lg-none" src={MovsTerm} alt="App" />
                            <img className="termViewxs2 d-block d-md-none" src={MovsTerm} alt="App" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Explicacion2;