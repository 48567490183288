import React from 'react';
import Prices from '../Componentes/Prices/prices';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Plans = () => {
  return (
    <main className="BodyContent">
      <Container>
        <Row><h1 className='planIdeal' style={{ marginTop: 30 }}>
          Encuentra el plan ideal para ti
        </h1></Row>
        <Row><p className='mejorPlan'>
          Puedes cambiarlo cuando tu quieras
        </p></Row>
        <div>
          <Prices />
        </div>
      </Container>
    </main>
  );
}

export default Plans;