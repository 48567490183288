import React from 'react';
import './App.css';
import Home from './pages/home';
import AboutUs from './pages/aboutus';
import Plans from './pages/plans';
import SolutionsPage from './pages/solutionsPage';
import Help from './pages/help';
import "bootstrap/dist/css/bootstrap.min.css";
import Header from './Componentes/Header/header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './Componentes/Footer/footer';
import Privacy from './pages/privacity';

const App = () => {
  return (
    <main className='mainCont'>
      <BrowserRouter>
        <Header/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/product" element={<AboutUs/>}/>
            <Route path="/plans" element={<Plans/>}/>
            <Route path="/solutions" element={<SolutionsPage/>}/>
            <Route path="/help" element={<Help/>} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="*" element={<Home/>}/> {/* Ruta predeterminada */}
          </Routes>
        <Footer/>
      </BrowserRouter>
    </main>
  );
}

export default App;