import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "./prices.css"
import { Link } from "react-router-dom";


const Prices = () => {
    return (
        <Container className="precios">
            <Row>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">Inicial</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">Gratis 30 días</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo">Try for free</Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPreciosProbar">Probar</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                            1 Empresa
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>1 Usuario </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>(Administrador)</span>
                                        </li>
                                        <li>
                                            1 Terminal Móvil
                                        </li>
                                        <li>
                                            1 Centro de Trabajo
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>200 Registros </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>(Movimientos)</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black'}}>400 Fotos/Documentos </span> 
                                            <span style={{ color: '#414141', fontWeight:200}}>(Almacenamiento)</span>
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">Básico</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">$100 dlls facturación mensual</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo">100dlls</Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPrecios">Suscribirse</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                            <span style={{ color: 'black', }}>1 Empresa </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>(Logo customizado)</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>2 Usuarios </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>(1 Administrador)</span>
                                        </li>
                                        <li>
                                            3 Terminales Móviles
                                        </li>
                                        <li>
                                            2 Centros de Trabajo
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>400 Registros </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>(Movimientos)</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black'}}>800 Fotos/Documentos </span> 
                                            <span style={{ color: '#414141', fontWeight:200}}>(Almacenamiento)</span>
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">Mediano</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">$200 dlls facturación mensual</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo">200dlls</Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPrecios">Suscribirse</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                            Multiempresa
                                        </li>
                                        <li>
                                            10 Usuarios
                                        </li>
                                        <li>
                                            5 Terminales Móviles
                                        </li>
                                        <li>
                                            10 Centros de Trabajo
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>1,000 Registros </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>(Movimientos)</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black'}}>1,600 Fotos/Documentos </span> 
                                            <span style={{ color: '#414141', fontWeight:200}}>(Almacenamiento)</span>
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">Avanzado</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">Agendar cita virtual</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo" style={{color: 'white'}}> * </Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPrecios">Agendar</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                            Multiempresa
                                        </li>
                                        <li>
                                            100 Usuarios
                                        </li>
                                        <li>
                                            Integración con API´s
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Prices;